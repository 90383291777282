import Vue from 'vue';
import App from './App.vue';
import '@/assets/css/index.css';

import router from './router';
import store from './store';

// vue-awesome-swiper 轮播图引用
import VueAwesomeSwiper from 'vue-awesome-swiper';

import 'swiper/dist/css/swiper.css';

Vue.use(VueAwesomeSwiper);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
