<template>
  <div class="from-ramp-contaner">
    <!-- PC端 -->
    <ul>
      <li v-for="(item, index) in showData" :key="index">
        <img :src="item.imgUrl" alt="" />
        <div class="title1">{{ item.title1 }}</div>
        <div class="info">{{ item.info }}</div>
      </li>
    </ul>
    <!-- 移动端 -->
    <div class="mobile-banner">
      <swiper class="swiper seconed" :options="fromRampOption">
        <swiper-slide class="slide" v-for="(item, index) in showData" :key="index">
          <div class="swiper-item">
            <img :src="item.imgUrl" alt="" />
            <div class="title1">{{ item.title1 }}</div>
            <div class="info">{{ item.info }}</div>
          </div>
        </swiper-slide>
      </swiper>
      <!-- 分页器 -->
      <div class="swiper-pagination22" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'fromRamp',
  data() {
    return {
      showData: [
        {
          imgUrl: require('@/assets/image/from-ramp-logo1.png'),
          title1: 'Seamless integration',
          info: 'Users stay longer in your app.'
        },
        {
          imgUrl: require('@/assets/image/from-ramp-logo2.png'),
          title1: 'Peerless payout speed',
          info: 'Fiat payout process begins within seconds, once crypto is received.'
        },
        {
          imgUrl: require('@/assets/image/from-ramp-logo3.png'),
          title1: 'Multiple crypto assets',
          info: 'Your users can off-ramp from 50+ cryptocurrencies.'
        },
        {
          imgUrl: require('@/assets/image/from-ramp-logo4.png'),
          title1: 'Multiple fiat support',
          info: 'Your users can off-ramp to more than 50+ fiat currencies.'
        },
        {
          imgUrl: require('@/assets/image/from-ramp-logo5.png'),
          title1: 'Competitive fees',
          info: 'Your users will appreciate the lowest fees in the industry.'
        },
        {
          imgUrl: require('@/assets/image/from-ramp-logo6.png'),
          title1: '100% compliant',
          info: 'With many licenses around the globe, you are assured to remain fully compliant.'
        }
      ],
      fromRampOption: {
        autoplay: true, // 自动播放
        initialSlide: 0, // 初始第几个图片
        // direction: 'vertical', // 切换方向
        speed: 500, // 切换速度
        slidesPerView: 1,
        spaceBetween: 40,
        loop: true,
        pagination: {
          el: '.swiper-pagination22', //分页器作用对象
          clickable: true //分页器小圆点是否可点击
        }
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.from-ramp-contaner {
  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    li {
      background: #ffffff;
      border: 1px solid #dadfeb;
      border-radius: 24px;
      width: 366px;
      height: 222px;

      padding: 24px;
      box-sizing: border-box;
      margin-bottom: 35px;
      img {
        width: 64px;
        height: 64px;
        margin-bottom: 16px;
      }
      .title1 {
        font-family: 'Bold';
        font-weight: 700;
        font-size: 22px;
        line-height: 30px;
        color: #13151a;
        margin-bottom: 12px;
      }
      .info {
        font-family: 'Regular';
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #525868;
      }
    }
  }
}
// 移动端样式
.mobile-banner {
  position: relative;
  .swiper {
    overflow-x: hidden;
    .swiper-item {
      padding: 24px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #dadfeb;
      border-radius: 24px;
      img {
        width: 64px;
        margin-bottom: 16px;
      }
      .title1 {
        font-family: 'Medium';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #13151a;
        margin-bottom: 10px;
      }
      .info {
        font-family: 'Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #525868;
        min-height: 50px;
      }
    }
  }
  .swiper-pagination22 {
    // margin-top: 20px;
    text-align: center;
    height: 3px;
    ::v-deep .swiper-pagination-bullet {
      display: inline-block;
      width: 30px;
      height: 3px;
      background: #13151a;
      opacity: 0.08;
      border-radius: 100px;
      margin-left: 12px;
    }
    ::v-deep .swiper-pagination-bullet-active {
      background: #0b48e6;
      opacity: 1;
    }
  }
}
@media (max-width: 1029px) {
  .from-ramp-contaner {
    ul {
      display: none;
    }
  }
}
@media (min-width: 1030px) {
  .mobile-banner {
    display: none;
  }
}
</style>
