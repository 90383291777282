var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-container"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"what-container"},[_c('div',{staticClass:"what-contant"},[_vm._m(6),_c('div',[_c('FromRamp')],1)])]),_vm._m(7),_c('div',{staticClass:"provides"},[_c('div',{staticClass:"learn-more"},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/image/logo_icon.png"),"alt":""}}),_c('div',{staticClass:"incorporated"},[_vm._v("Incorporated on October 22, 2022 as a C-Corporation in the State of California, Alchemy Pay, Inc. (NMLS ID: 2449570) is a payment gateway provider that seamlessly connects crypto and global fiat currencies for businesses, developers, and users.")]),_c('div',{staticClass:"us-Licenses",on:{"click":_vm.linkUsLicenses}},[_vm._v("US Licenses "),_c('span',{staticStyle:{"margin-left":"4px"}},[_vm._v(" © ALCHEMYPAY 2024")])])]),_c('div',{staticClass:"other"},[_c('div',{staticClass:"products"},[_c('ul',[_c('li',{staticClass:"title"},[_vm._v("Products")]),_c('li',{staticClass:"link-name",on:{"click":_vm.goOnRamp}},[_vm._v("On-Ramp")]),_c('li',{staticClass:"link-name",on:{"click":_vm.goOffRamp}},[_vm._v("Off-Ramp")])])]),_vm._m(8)])]),_vm._m(9)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bridging-news"},[_c('div',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/image/logo_icon.png"),"alt":""}}),_c('div')]),_c('div',{staticClass:"bridging"},[_c('div',{staticClass:"bridging-content"},[_c('div',{staticClass:"left1"},[_c('img',{staticClass:"img1",attrs:{"src":require("@/assets/image/title-icon.png"),"alt":""}}),_c('img',{staticClass:"img2",attrs:{"src":require("@/assets/image/title-img2.png"),"alt":""}}),_c('div',{staticClass:"title"},[_vm._v("Bridging the "),_c('span',[_vm._v("fiat")]),_vm._v(" and "),_c('span',[_vm._v("crypto")]),_vm._v(" global economies")]),_c('div',[_c('div',{staticClass:"info"},[_vm._v("Crucial payment support for Web3 platforms is here. Enable your users to buy and sell crypto from your app.")])]),_c('div',{staticClass:"button"},[_c('span',{staticClass:"apply button-box2"},[_c('a',{attrs:{"href":"mailto:info@alchemypay.org"}},[_vm._v("Contact us")])])])]),_c('div',{staticClass:"right1"},[_c('img',{staticClass:"img3",attrs:{"src":require("@/assets/image/img3.png"),"alt":""}}),_c('img',{staticClass:"img4",attrs:{"src":require("@/assets/image/img4.png"),"alt":""}}),_c('img',{staticClass:"img5",attrs:{"src":require("@/assets/image/img5.png"),"alt":""}}),_c('div',{staticClass:"right1-logo"},[_c('img',{attrs:{"src":require("@/assets/image/home_img.png"),"alt":""}})])])])]),_c('div',{staticClass:"home-bg"},[_c('canvas',{staticClass:"isLoaded",staticStyle:{"width":"100vw"},attrs:{"id":"gradient-canvas"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"crypto-container",attrs:{"id":"OnRamp"}},[_c('div',{staticClass:"crypto-contant"},[_c('div',{staticClass:"crypto-left"},[_c('div',{staticClass:"title"},[_vm._v("Crypto "),_c('span',[_vm._v("On-ramp")])]),_c('div',{staticClass:"info"},[_vm._v("Direct fiat to crypto purchases that provide mainstream-friendly access to crypto and web3 services. The ramps also provide users with the ability to sell crypto and remit funds to bank accounts in over 50 fiat currencies.")]),_c('div',{staticClass:"button button-box1"},[_c('a',{attrs:{"href":"mailto:info@alchemypay.org"}},[_vm._v("Contact us")])])]),_c('div',{staticClass:"crypto-right"},[_c('img',{attrs:{"src":require("@/assets/image/crypto_img.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popular-container"},[_c('div',{staticClass:"popular-content"},[_c('div',{staticClass:"popular-left"},[_c('img',{attrs:{"src":require("@/assets/image/popular_img.png"),"alt":""}})]),_c('div',{staticClass:"popular-right"},[_c('div',{staticClass:"title"},[_vm._v("All "),_c('span',[_vm._v("popular")]),_vm._v(" global and "),_c('span',[_vm._v("local")]),_vm._v(" payment methods")]),_c('div',{staticClass:"info"},[_vm._v("Mainstream fiat payments like Visa, Mastercard, mobile wallets, and regional providers.")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("Credit Card")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("Mobile Wallet")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("Debit Card")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("Bank Transfer")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accept-container"},[_c('div',{staticClass:"accept-contant"},[_c('div',{staticClass:"accept-left"},[_vm._v("Accept fast, secure fiat payments from around the world at a low cost.")]),_c('div',{staticClass:"accept-right"},[_vm._v(" Our 300+ fiat payment rails allow users around the world to deposit funds via a variety of local and global fiat payment methods. We enable fiat payment acceptance for global services such as crypto exchanges, DeFi protocols, crypto wallets, metaverse virtual worlds, NFT marketplaces, and other web3 services. These payment rails allow both newcomers and expert users to enter these services directly with their fiat funds. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"instant-container"},[_c('div',{staticClass:"instant-content"},[_c('div',{staticClass:"instant-left"},[_c('div',{staticClass:"title"},[_vm._v("Instant "),_c('span',[_vm._v("verification")]),_vm._v(" and "),_c('span',[_vm._v("onboarding")])]),_c('div',{staticClass:"info"},[_vm._v("A user-friendly experience that blocks fraud, not customers.")]),_c('div',{staticClass:"list"},[_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("ID Verification")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("Facial Recognition")])]),_c('div',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/image/popular_icon.png"),"alt":""}}),_c('span',[_vm._v("KYC and KYT as a Service")])])])]),_c('div',{staticClass:"instant-right"},[_c('img',{attrs:{"src":require("@/assets/image/instant_img.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"crypto-container crypto-container1",attrs:{"id":"OffRamp"}},[_c('div',{staticClass:"crypto-contant"},[_c('div',{staticClass:"crypto-left"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("Crypto to fiat")]),_vm._v(" Off-ramp is here")]),_c('div',{staticClass:"info"},[_vm._v(" With Alchemy Pay, moving funds from crypto to local fiat currency has never been easier. Enable your users to sell crypto into fiat and have their funds sent to bank accounts around the world. Users can bypass complex processes and enjoy simple, quick fiat payouts with the industry’s most competitive fees. Most importantly, your users can do this from your platform. ")])]),_c('div',{staticClass:"crypto-right"},[_c('img',{attrs:{"src":require("@/assets/image/crypto_img1.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('span',[_vm._v("What do you get")]),_vm._v(" from ramp?")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"functions-with"},[_c('div',{staticClass:"title"},[_vm._v("Alchemy Pay Off-ramp "),_c('span',[_vm._v("functions with:")])]),_c('ul',[_c('li',[_c('span',{staticClass:"title1"},[_vm._v("NFT Marketplaces")]),_c('span',{staticClass:"info"},[_vm._v("Allow your NFT creators and traders the option to move into their local fiat currency with ease.")])]),_c('li',{staticClass:"no-margin"},[_c('span',{staticClass:"title1 deFi"},[_vm._v("DeFi Protocols")]),_c('span',{staticClass:"info"},[_vm._v("DeFi users typically want to organise their funds between both crypto and fiat. Enable them to do so with minimum fuss.")])]),_c('li',[_c('span',{staticClass:"title1 play"},[_vm._v("Play-2-Earn Games")]),_c('span',{staticClass:"info"},[_vm._v("Play-to-earn games provide a very real opportunity for the new gig economy. Enable your users to feel the benefit in both crypto and fiat.")])]),_c('li',{staticClass:"no-margin"},[_c('span',{staticClass:"title1 wallets"},[_vm._v("Crypto Wallets")]),_c('span',{staticClass:"info"},[_vm._v("Make your wallet a comprehensive system for all your user’s needs so you're all they'll ever need.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products contact-us"},[_c('ul',[_c('li',{staticClass:"title"},[_vm._v("Contact us")]),_c('li',[_c('a',{staticClass:"email",attrs:{"href":"mailto:info@alchemypay.org"}},[_vm._v("info@alchemypay.org")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"canvas-bg"},[_c('canvas',{staticClass:"isLoaded",attrs:{"id":"gradient-canvas1"}})])
}]

export { render, staticRenderFns }