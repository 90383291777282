<template>
  <div class="home-container">
    <div class="bridging-news">
      <div class="header">
        <img src="@/assets/image/logo_icon.png" alt="" />
        <div></div>
      </div>
      <div class="bridging">
        <div class="bridging-content">
          <div class="left1">
            <img class="img1" src="@/assets/image/title-icon.png" alt="" />
            <img class="img2" src="@/assets/image/title-img2.png" alt="" />
            <div class="title">Bridging the <span>fiat</span> and <span>crypto</span> global economies</div>
            <div>
              <div class="info">Crucial payment support for Web3 platforms is here. Enable your users to buy and sell crypto from your app.</div>
            </div>

            <div class="button">
              <span class="apply button-box2">
                <a href="mailto:info@alchemypay.org">Contact us</a>
              </span>
            </div>
          </div>
          <div class="right1">
            <img class="img3" src="@/assets/image/img3.png" alt="" />
            <img class="img4" src="@/assets/image/img4.png" alt="" />
            <img class="img5" src="@/assets/image/img5.png" alt="" />
            <div class="right1-logo">
              <img src="@/assets/image/home_img.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="home-bg">
        <canvas class="isLoaded" id="gradient-canvas" style="width: 100vw"> </canvas>
      </div>
    </div>
    <div class="crypto-container" id="OnRamp">
      <div class="crypto-contant">
        <div class="crypto-left">
          <div class="title">Crypto <span>On-ramp</span></div>
          <div class="info">Direct fiat to crypto purchases that provide mainstream-friendly access to crypto and web3 services. The ramps also provide users with the ability to sell crypto and remit funds to bank accounts in over 50 fiat currencies.</div>
          <div class="button button-box1">
            <a href="mailto:info@alchemypay.org">Contact us</a>
          </div>
        </div>
        <div class="crypto-right">
          <img src="@/assets/image/crypto_img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="popular-container">
      <div class="popular-content">
        <div class="popular-left">
          <img src="@/assets/image/popular_img.png" alt="" />
        </div>
        <div class="popular-right">
          <div class="title">All <span>popular</span> global and <span>local</span> payment methods</div>
          <div class="info">Mainstream fiat payments like Visa, Mastercard, mobile wallets, and regional providers.</div>
          <div class="list">
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>Credit Card</span>
            </div>
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>Mobile Wallet</span>
            </div>
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>Debit Card</span>
            </div>
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>Bank Transfer</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="accept-container">
      <div class="accept-contant">
        <div class="accept-left">Accept fast, secure fiat payments from around the world at a low cost.</div>
        <div class="accept-right">
          Our 300+ fiat payment rails allow users around the world to deposit funds via a variety of local and global fiat payment methods. We enable fiat payment acceptance for global services such as crypto exchanges, DeFi protocols, crypto wallets, metaverse virtual worlds, NFT marketplaces, and
          other web3 services. These payment rails allow both newcomers and expert users to enter these services directly with their fiat funds.
        </div>
      </div>
    </div>
    <div class="instant-container">
      <div class="instant-content">
        <div class="instant-left">
          <div class="title">Instant <span>verification</span> and <span>onboarding</span></div>
          <div class="info">A user-friendly experience that blocks fraud, not customers.</div>
          <div class="list">
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>ID Verification</span>
            </div>
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>Facial Recognition</span>
            </div>
            <div class="item">
              <img src="@/assets/image/popular_icon.png" alt="" />
              <span>KYC and KYT as a Service</span>
            </div>
          </div>
        </div>
        <div class="instant-right">
          <img src="@/assets/image/instant_img.png" alt="" />
        </div>
      </div>
    </div>
    <div class="crypto-container crypto-container1" id="OffRamp">
      <div class="crypto-contant">
        <div class="crypto-left">
          <div class="title"><span>Crypto to fiat</span> Off-ramp is here</div>
          <div class="info">
            With Alchemy Pay, moving funds from crypto to local fiat currency has never been easier. Enable your users to sell crypto into fiat and have their funds sent to bank accounts around the world. Users can bypass complex processes and enjoy simple, quick fiat payouts with the industry’s
            most competitive fees. Most importantly, your users can do this from your platform.
          </div>
        </div>
        <div class="crypto-right">
          <img src="@/assets/image/crypto_img1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="what-container">
      <div class="what-contant">
        <div class="top"><span>What do you get</span> from ramp?</div>
        <div>
          <FromRamp></FromRamp>
        </div>
      </div>
    </div>
    <div class="functions-with">
      <div class="title">Alchemy Pay Off-ramp <span>functions with:</span></div>
      <ul>
        <li>
          <span class="title1">NFT Marketplaces</span>
          <span class="info">Allow your NFT creators and traders the option to move into their local fiat currency with ease.</span>
        </li>
        <li class="no-margin">
          <span class="title1 deFi">DeFi Protocols</span>
          <span class="info">DeFi users typically want to organise their funds between both crypto and fiat. Enable them to do so with minimum fuss.</span>
        </li>
        <li>
          <span class="title1 play">Play-2-Earn Games</span>
          <span class="info">Play-to-earn games provide a very real opportunity for the new gig economy. Enable your users to feel the benefit in both crypto and fiat.</span>
        </li>
        <li class="no-margin">
          <span class="title1 wallets">Crypto Wallets</span>
          <span class="info">Make your wallet a comprehensive system for all your user’s needs so you're all they'll ever need.</span>
        </li>
      </ul>
    </div>
    <div class="provides">
      <div class="learn-more">
        <div class="logo">
          <img class="logo" src="@/assets/image/logo_icon.png" alt="" />
          <div class="incorporated">Incorporated on October 22, 2022 as a C-Corporation in the State of California, Alchemy Pay, Inc. (NMLS ID: 2449570) is a payment gateway provider that seamlessly connects crypto and global fiat currencies for businesses, developers, and users.</div>
          <div class="us-Licenses" @click="linkUsLicenses">US Licenses <span style="margin-left: 4px"> &copy; ALCHEMYPAY 2024</span></div>
        </div>
        <div class="other">
          <div class="products">
            <ul>
              <li class="title">Products</li>
              <li class="link-name" @click="goOnRamp">On-Ramp</li>
              <li class="link-name" @click="goOffRamp">Off-Ramp</li>
            </ul>
          </div>
          <div class="products contact-us">
            <ul>
              <li class="title">Contact us</li>
              <li><a class="email" href="mailto:info@alchemypay.org">info@alchemypay.org</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div class="canvas-bg">
        <canvas class="isLoaded" id="gradient-canvas1"> </canvas>
      </div>
    </div>
  </div>
</template>

<script>
import Gradient from '@/tool/Gradient.js';
import FromRamp from '@/components/fromRamp/index.vue';
export default {
  name: 'Home',
  components: { FromRamp },
  data() {
    return {
      gradient: null // 渐变背景类名
    };
  },
  methods: {
    // 实现滚动条平滑滚动的方法
    scrollToCmtList(id) {
      let ele = document.getElementById(id);
      // console.log('ele', ele);
      ele.scrollIntoView({ block: 'start', behavior: 'smooth' });
    },
    linkUsLicenses() {
      this.$router.push('/usLicenses');
    },
    goOnRamp() {
      this.scrollToCmtList('OnRamp');
    },

    goOffRamp() {
      this.scrollToCmtList('OffRamp');
    },

    // 初始化动态背景
    initGradient(id) {
      this.gradient = new Gradient();
      this.gradient.initGradient(id);
    }
  },

  mounted() {
    this.initGradient('#gradient-canvas');
    this.initGradient('#gradient-canvas1');
  },
  destroyed() {
    this.gradient.pause();
  }
};
</script>

<style lang="scss" scoped>
#gradient-canvas {
  --gradient-color-1: #1cbaf7;
  --gradient-color-2: #1cbaf7;
  --gradient-color-3: #1509f9;
  --gradient-color-4: #961aee;
  opacity: 0;
  // filter: blur(1px);
  // -webkit-filter: blur(1px);
}
#gradient-canvas.isLoaded {
  opacity: 1;
  transition: opacity 0.5s ease-in 50ms;
}

// .home-bg::after {
//   content: '';
//   display: block;
//   position: relative;
//   top: 130px;
//   width: 100vw;
//   height: 10px;
//   background: #f7f9fc;
//   // background: red;
//   // display: none;
// }

#gradient-canvas1 {
  --gradient-color-1: #1cbaf7;
  --gradient-color-2: #1cbaf7;
  --gradient-color-3: #1509f9;
  --gradient-color-4: #961aee;
  opacity: 0;
}
#gradient-canvas1.isLoaded {
  opacity: 1;
  transition: opacity 0.5s ease-in 50ms;
  // pointer-events: none;
}

@media (max-width: 1029px) {
  .home-container {
    position: relative;
    height: 110vh;
    .bridging-news {
      height: 100%;
      position: relative;
      .header {
        max-width: calc(100% - 48px);
        margin: 0 auto;
        position: relative;
        z-index: 3;
        display: flex;
        img {
          margin-top: 24px;
          width: auto;
          height: 30px;
        }
      }
      .bridging {
        .bridging-content {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding-top: 61px;
          padding-bottom: 50px;
          box-sizing: border-box;
          max-width: calc(100% - 48px);
          margin: 0 auto;
          .left1 {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .img1 {
              width: 65px;
              position: absolute;
              left: -30px;
              z-index: -1;
              display: none;
            }
            .img2 {
              width: 64px;
              position: absolute;
              right: -30px;
              top: -10px;
              z-index: -1;
              display: none;
            }
            .title {
              font-family: 'Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 36px;
              line-height: 44px;
              color: #ffffff;
              margin-bottom: 12px;
              z-index: 1;
              text-align: center;
              span {
                color: rgba(61, 255, 243, 1);
              }
            }
            .info {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 16px;
              text-align: center;
              // max-width: 558px;
            }
            .button {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 26px;
              color: #ffffff;
              display: flex;
              // display: inline-block;
              transition: all 0.35s ease;

              span {
                padding: 9px 32px;
                border-radius: 9999px;
                background: rgba(255, 255, 255, 0.1);
                border: 1.5px solid #ffffff;
                color: #fff;
                a {
                  color: #fff;
                }
              }
            }
          }

          .right1 {
            margin-top: 36px;
            position: relative;
            z-index: 1;
            .img3 {
              width: 50px;
              position: absolute;
              top: -60px;
              right: 125px;
              z-index: -1;
              display: none;
            }
            .img4 {
              height: 56px;
              position: absolute;
              bottom: -40px;
              right: -30px;
              z-index: 1;
              display: none;
            }
            .img5 {
              width: 122px;
              position: absolute;
              bottom: -55px;
              left: -105px;
              z-index: -1;
              display: none;
            }
            .right1-logo {
              img {
                width: 100%;
                // height: 407px;
              }
            }
          }
        }
      }
      .home-bg {
        height: 100%;
        #gradient-canvas {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
      }
    }
  }
  .crypto-container {
    background: #f7f9fc;
    position: relative;
    z-index: 3;
    .crypto-contant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      padding-top: 64px;
      padding-bottom: 24px;
      box-sizing: border-box;
      max-width: calc(100% - 48px);
      margin: 0 auto;

      .crypto-left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          color: #13151a;
          margin-bottom: 24px;
          text-align: center;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #525868;
          margin-bottom: 16px;
          text-align: center;
        }
        .button {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 26px;
          color: #ffffff;

          width: 156px;
          height: 44px;
          background: #0b48e6;
          border-radius: 9999px;

          display: flex;
          justify-content: center;
          align-items: center;
          a {
            color: #fff;
          }
        }
      }
      .crypto-right {
        img {
          width: 100%;
        }
      }
    }
  }
  .popular-container {
    background: #fff;
    .popular-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      padding: 64px 0;
      box-sizing: border-box;
      max-width: calc(100% - 48px);
      margin: 0 auto;
      .popular-left {
        img {
          width: 100%;
        }
      }
      .popular-right {
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;
          color: #13151a;
          margin-bottom: 16px;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #525868;
          margin-bottom: 15px;
          max-width: 530px;
        }
        .list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          // grid-auto-columns: 1fr 1fr;
          width: 300px;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            span {
              font-family: 'Regular';
              font-style: normal;
              font-size: 14px;
              line-height: 21px;
              line-height: 24px;
              color: #525868;
            }
          }
        }
      }
    }
  }
  .accept-container {
    .accept-contant {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      padding: 16px;
      box-sizing: border-box;
      max-width: calc(100% - 48px);
      margin: 0 auto;

      background: #f7f9fc;
      border-radius: 20px;
      .accept-left {
        font-family: 'Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #13151a;
        max-width: 564px;
        margin-bottom: 12px;
      }
      .accept-right {
        font-family: 'Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #525868;
        max-width: 564px;
      }
    }
  }
  .instant-container {
    background: #fff;
    .instant-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 64px 0;
      box-sizing: border-box;
      max-width: calc(100% - 48px);
      margin: 0 auto;
      .instant-left {
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          color: #13151a;
          margin-bottom: 16px;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #525868;
          margin-bottom: 16px;
          max-width: 530px;
        }
        .list {
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            span {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #525868;
            }
          }
        }
      }
      .instant-right {
        margin-top: 36px;
        img {
          width: 100%;
        }
      }
    }
  }

  .crypto-container1 {
    background: #f7f9fc;
    .crypto-contant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;

      padding-top: 54px;
      padding-bottom: 5px;
      box-sizing: border-box;
      max-width: calc(100% - 48px);
      margin: 0 auto;

      .crypto-left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 34px;
          color: #13151a;
          margin-bottom: 24px;
          text-align: left;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #525868;
          margin-bottom: 40px;
          // max-width: 530px;
          text-align: left;
        }
      }
      .crypto-right {
        // margin-left: 20px;
        img {
          width: 100%;
        }
      }
    }
  }

  .what-container {
    .what-contant {
      width: calc(100% - 48px);
      margin: 0 auto;
      margin-top: 64px;
      .top {
        font-family: 'Bold';
        font-weight: 700;
        font-size: 24px;
        line-height: 34px;
        color: #13151a;
        margin-bottom: 32px;
        text-align: center;
        span {
          color: #0b48e6;
        }
      }
    }
  }

  .functions-with {
    width: calc(100% - 48px);
    margin: 0 auto;
    padding-top: 64px;
    padding-bottom: 64px;
    box-sizing: border-box;
    .title {
      font-family: 'Bold';
      font-size: 24px;
      line-height: 34px;
      color: #13151a;
      margin-bottom: 30px;
      text-align: center;
      span {
        color: #0b48e6;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      li {
        width: 150px;
        background: #f7f9fc;
        border-radius: 20px;
        box-sizing: border-box;

        margin-bottom: 16px;
        margin-right: 8px;

        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all 0.5s ease 0s;
        .title1 {
          font-family: 'Medium';
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          color: #ffffff;

          text-align: center;
          padding: 16px 0;
          box-sizing: border-box;
          background-image: url('@/assets/image/functions-with-bg2.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 0 0;
        }
        .deFi {
          background-image: url('@/assets/image/functions-with-bg1.png');
        }
        .play {
          background-image: url('@/assets/image/functions-with-bg3.png');
        }
        .wallets {
          background-image: url('@/assets/image/functions-with-bg4.png');
        }
        .info {
          font-family: 'Regular';
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #525868;
          padding: 16px;
          box-sizing: border-box;
        }
      }
      li.no-margin {
        margin-right: 0px;
      }
    }
  }

  .provides {
    position: relative;
    height: 400px;
    overflow: hidden;
    .canvas-bg {
      width: 100%;
      position: absolute;
      top: 0;
      // left: 0;
      z-index: 0;
      canvas {
        width: 100%;
        height: auto;
      }
    }
    .learn-more {
      position: relative;
      z-index: 1;

      font-style: normal;
      color: #ffffff;
      max-width: calc(100% - 48px);
      margin: 0 auto;
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .logo {
        img {
          width: auto;
          height: 30px;
          margin-bottom: 20px;
        }
        .incorporated {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #ffffff;
          // max-width: 656px;
        }
        .us-Licenses {
          margin-top: 30px;
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
      }
      .other {
        display: flex;
        margin-top: 40px;
        .products {
          margin-right: 70px;
          ul {
            li {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 16px;
              color: #ffffff;
              margin-bottom: 20px;
              // cursor: pointer;
            }
            li.title {
              font-family: 'Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 20px;
              // cursor: auto;
            }
          }
        }
        .contact-us {
          ul {
            li {
              a {
                color: #3dfff3;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1030px) {
  .home-container {
    position: relative;
    height: 100vh;
    .bridging-news {
      height: 100%;
      position: relative;
      .header {
        max-width: calc(1440px - 77px - 77px);
        margin: 0 auto;
        position: relative;
        z-index: 3;
        display: flex;
        img {
          margin-top: 33px;
          width: auto;
          height: 33px;
        }
      }
      .bridging {
        .bridging-content {
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 130px;
          padding-bottom: 161px;
          box-sizing: border-box;
          max-width: calc(1440px - 117px - 117px);
          margin: 0 auto;
          .left1 {
            position: relative;
            z-index: 1;
            .img1 {
              width: 65px;
              position: absolute;
              left: -30px;
              z-index: -1;
            }
            .img2 {
              width: 64px;
              position: absolute;
              right: -30px;
              top: -10px;
              z-index: -1;
            }
            .title {
              font-family: 'Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 68px;
              line-height: 82px;
              color: #ffffff;
              margin-bottom: 24px;
              z-index: 1;
              span {
                color: rgba(61, 255, 243, 1);
              }
            }
            .info {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 20px;
              line-height: 30px;
              color: #ffffff;
              margin-bottom: 40px;
              max-width: 558px;
            }
            .button {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 500;
              font-size: 18px;
              line-height: 19px;
              color: #ffffff;
              display: flex;
              // display: inline-block;
              transition: all 0.35s ease;

              span {
                padding: 15px 32px;
                cursor: pointer;
                border-radius: 9999px;
                background: rgba(255, 255, 255, 0.1);
                border: 1.5px solid #ffffff;
                color: #fff;
                a {
                  color: #fff;
                }
              }
            }
          }

          .right1 {
            margin-left: 87px;
            position: relative;
            z-index: 1;
            .img3 {
              width: 50px;
              position: absolute;
              top: -60px;
              right: 125px;
              z-index: -1;
            }
            .img4 {
              height: 56px;
              position: absolute;
              bottom: -40px;
              right: -30px;
              z-index: 1;
            }
            .img5 {
              width: 122px;
              position: absolute;
              bottom: -55px;
              left: -105px;
              z-index: -1;
            }
            .right1-logo {
              img {
                width: 504px;
                // height: 407px;
              }
            }
          }
        }
      }
      .home-bg {
        height: 100%;
        #gradient-canvas {
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
        }
      }
    }
  }
  .crypto-container {
    background: #f7f9fc;
    position: relative;
    z-index: 3;
    .crypto-contant {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 80px 0;
      box-sizing: border-box;
      max-width: calc(1440px - 130px - 130px);
      margin: 0 auto;
      .crypto-left {
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 44px;
          line-height: 53px;
          color: #13151a;
          margin-bottom: 24px;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #525868;
          margin-bottom: 40px;
          max-width: 530px;
        }
        .button {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 19px;
          color: #ffffff;

          width: 174px;
          height: 52px;
          background: #0b48e6;
          border-radius: 9999px;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          a {
            color: #fff;
          }
        }
      }
      .crypto-right {
        margin-left: 20px;
        img {
          width: 630px;
        }
      }
    }
  }
  .popular-container {
    background: #fff;
    .popular-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 80px 0;
      box-sizing: border-box;
      max-width: calc(1440px - 130px - 130px);
      margin: 0 auto;
      .popular-left {
        img {
          width: 640px;
          height: 640px;
        }
      }
      .popular-right {
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 44px;
          line-height: 53px;
          color: #13151a;
          margin-bottom: 24px;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #525868;
          margin-bottom: 26px;
          max-width: 530px;
        }
        .list {
          display: grid;
          grid-template-columns: 1fr 1fr;
          // grid-auto-columns: 1fr 1fr;
          width: 300px;
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            span {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #525868;
            }
          }
        }
      }
    }
  }
  .accept-container {
    .accept-contant {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 60px;
      box-sizing: border-box;
      max-width: calc(1440px - 77px - 77px);
      margin: 0 auto;

      background: #f7f9fc;
      border-radius: 40px;
      .accept-left {
        font-family: 'Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 48px;
        color: #13151a;
        max-width: 564px;
      }
      .accept-right {
        font-family: 'Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #525868;
        max-width: 564px;
      }
    }
  }
  .instant-container {
    background: #fff;
    .instant-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 130px 0;
      box-sizing: border-box;
      max-width: calc(1440px - 130px - 130px);
      margin: 0 auto;
      .instant-left {
        .title {
          font-family: 'Bold';
          font-style: normal;
          font-weight: 700;
          font-size: 44px;
          line-height: 53px;
          color: #13151a;
          margin-bottom: 24px;
          span {
            color: #0b48e6;
          }
        }
        .info {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          color: #525868;
          margin-bottom: 26px;
          max-width: 530px;
        }
        .list {
          .item {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }
            span {
              font-family: 'Regular';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
              color: #525868;
            }
          }
        }
      }
      .instant-right {
        img {
          width: 530px;
          height: 530px;
        }
      }
    }
  }
  .what-container {
    .what-contant {
      width: calc(1440px - 130px - 130px);
      margin: 0 auto;
      margin-top: 100px;
      .top {
        font-family: 'Bold';
        font-weight: 700;
        font-size: 44px;
        line-height: 53px;
        color: #13151a;
        margin-bottom: 80px;
        text-align: center;
        span {
          color: #0b48e6;
        }
      }
    }
  }
  .functions-with {
    width: calc(1440px - 130px - 130px);
    margin: 0 auto;
    padding-top: 130px;
    padding-bottom: 162px;
    box-sizing: border-box;
    .title {
      font-family: 'Bold';
      font-weight: 700;
      font-size: 44px;
      line-height: 53px;
      color: #13151a;
      margin-bottom: 70px;
      text-align: center;
      span {
        color: #0b48e6;
      }
    }
    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      li {
        width: 265px;
        // height: 270px;
        background: #f7f9fc;
        border-radius: 24px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: all 0.5s ease 0s;
        .title1 {
          font-family: 'Medium';
          font-weight: 700;
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;

          text-align: center;
          padding: 20px 0;
          box-sizing: border-box;
          background-image: url('@/assets/image/functions-with-bg2.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-position: 0 0;
        }
        .deFi {
          background-image: url('@/assets/image/functions-with-bg1.png');
        }
        .play {
          background-image: url('@/assets/image/functions-with-bg3.png');
        }
        .wallets {
          background-image: url('@/assets/image/functions-with-bg4.png');
        }
        .info {
          font-family: 'Regular';
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #525868;
          padding: 24px;
          padding-bottom: 40px;
          box-sizing: border-box;
        }
      }
      li:hover {
        background: #fff;
        transform: translateY(-12px);
        transition: all 0.5s ease 0s;
        color: #13151a;
        box-shadow: 0px 0px 88px rgba(0, 0, 0, 0.1);
        .info {
          color: #13151a;
        }
      }
    }
  }
  .provides {
    position: relative;
    height: 300px;
    overflow: hidden;
    .canvas-bg {
      width: 100%;
      position: absolute;
      top: 0;
      // left: 0;
      z-index: 0;
      canvas {
        width: 100%;
        height: auto;
      }
    }
    .learn-more {
      position: relative;
      z-index: 1;

      font-style: normal;
      color: #ffffff;
      max-width: calc(1440px - 130px - 130px);
      margin: 0 auto;
      margin-top: 60px;
      display: flex;
      // justify-content: space-between;
      .logo {
        img {
          width: auto;
          height: 33px;
          margin-bottom: 24px;
        }
        .incorporated {
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #ffffff;
          max-width: 656px;
        }
        .us-Licenses {
          display: inline-block;
          margin-top: 30px;
          font-family: 'Regular';
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #ffffff;
          cursor: pointer;
          display: flex;
          align-items: center;
        }
        .us-Licenses:hover {
          text-decoration: underline;
        }
      }
      .other {
        display: flex;
        margin-left: 145px;
        .products {
          ul {
            li {
              font-family: 'Regular';

              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 19px;

              color: #ffffff;
              margin-bottom: 24px;
              cursor: pointer;
            }
            li.title {
              font-family: 'Bold';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 24px;
              color: #ffffff;
              margin-bottom: 32px;
              cursor: auto;
            }
          }
        }
        .link-name:hover {
          text-decoration: underline;
        }
        .contact-us {
          margin-left: 130px;
          ul {
            li {
              a {
                color: #3dfff3;
              }
              a:hover {
                text-decoration: none;
                border-bottom: 1px solid #3dfff3;
              }
            }
          }
        }
      }
    }
  }
}
</style>
