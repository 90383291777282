var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"UsLicenses-container"},[_c('div',{staticClass:"title-content"},[_c('img',{staticClass:"title-logo",attrs:{"src":require("./../assets/image/licenses-icon.png")},on:{"click":_vm.goHome}})]),_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"provides"},[_c('div',{staticClass:"learn-more"},[_vm._m(3),_c('div',{staticClass:"other"},[_c('div',{staticClass:"products"},[_c('ul',[_c('li',{staticClass:"title"},[_vm._v("Products")]),_c('li',{staticClass:"link-name",on:{"click":_vm.goHome}},[_vm._v("On-Ramp")]),_c('li',{staticClass:"link-name",on:{"click":_vm.goHome}},[_vm._v("Off-Ramp")])])]),_vm._m(4)])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-us"},[_c('div',{staticClass:"us-text"},[_vm._v("US Licenses")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"list-box"},[_c('div',{staticClass:"list-title"},[_vm._v(" Alchemy Pay Inc is licensed in the following U.S. jurisdictions. ")]),_c('div',{staticClass:"item-list"},[_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("Jurisdiction")]),_c('div',[_vm._v("License Number")]),_c('div',[_vm._v("Regulatory Agency")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("Arkansas")]),_c('div',[_vm._v("131437")]),_c('div',[_vm._v("Arkansas Securities Department")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("Iowa")]),_c('div',[_vm._v("2023-0136")]),_c('div',[_vm._v("State of Iowa Division of Banking")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("Minnesota")]),_c('div',[_vm._v("MN-MT-2449570")]),_c('div',[_vm._v(" Minnesota Department of Commerce, Financial Institutions Division ")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("New Hampshire")]),_c('div',[_vm._v("27020-MT")]),_c('div',[_vm._v("New Hampshire Banking Department")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("New Mexico")]),_c('div',[_vm._v("None")]),_c('div',[_vm._v("New Mexico Financial Institutions Division")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("Oklahoma")]),_c('div',[_vm._v("None")]),_c('div',[_vm._v("Oklahoma Banking Department")])]),_c('div',{staticClass:"item-row"},[_c('div',[_vm._v("Oregon")]),_c('div',[_vm._v("None")]),_c('div',[_vm._v("Oregon Division of Financial Regulation")])]),_c('div',{staticClass:"item-row"},[_c('div',{staticClass:"Iowa"},[_vm._v("Wyoming")]),_c('div',{staticClass:"Iowa"},[_vm._v("7360")]),_c('div',{staticClass:"Iowa"},[_vm._v("Department of Audit")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"state-content"},[_c('div',{staticClass:"state-title"},[_vm._v("State Disclosures:")]),_c('div',{staticClass:"state-text-box"},[_c('div',{staticClass:"text-title"},[_vm._v("Arkansas")]),_c('div',[_vm._v(" If you have a complaint, please contact Alchemy Pay Inc at +1(866)584-9768 or "),_c('a',{staticClass:"link-style",attrs:{"href":"mailto:Contact@allins.io","target":"_blank"}},[_vm._v("info@alchemypay.org.")])]),_c('div',[_vm._v(" You may also contact Arkansas Securities Department if your complaints or disputes regarding Alchemy Pay Inc money transmission services remains ")]),_c('div',[_vm._v(" unsolved, you may file a complaint with the Arkansas Security Department by clicking "),_c('a',{staticClass:"link-style",attrs:{"href":"https://securities.arkansas.gov/file-a-complaint-2/","target":"_blank"}},[_vm._v("here.")]),_vm._v(" You may also fill out the "),_c('a',{staticClass:"link-style",attrs:{"href":"https://securities.arkansas.gov/wp-content/uploads/2022/07/ComplaintForm-July-2022.pdf","target":"_blank"}},[_vm._v("complaint form")]),_vm._v(" and email to ")]),_c('div',[_vm._v(" ASDInfo@securities.arkansas.gov or mail to the Arkansas Security Department at: ")]),_c('div',{staticClass:"bottom-text"},[_c('div',[_vm._v("1 COMMERCE WAY, SUITE 402")]),_c('div',[_vm._v("LITTLE ROCK, ARKANSAS 72202")]),_c('div',[_vm._v("TELEPHONE: (501) 324-9260")]),_c('div',[_vm._v("FACSIMILE: (501) 324-9268")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/image/logo_icon.png"),"alt":""}}),_c('div',{staticClass:"incorporated"},[_vm._v(" Incorporated on October 22, 2022 as a C-Corporation in the State of California, Alchemy Pay, Inc. (NMLS ID: 2449570) is a payment gateway provider that seamlessly connects crypto and global fiat currencies for businesses, developers, and users. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"products contact-us"},[_c('ul',[_c('li',{staticClass:"title"},[_vm._v("Contact us")]),_c('li',[_c('a',{staticClass:"email",attrs:{"href":"mailto:info@alchemypay.org"}},[_vm._v("info@alchemypay.org")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"canvas-bg"},[_c('canvas',{staticClass:"isLoaded",attrs:{"id":"gradient-canvas1"}})])
}]

export { render, staticRenderFns }